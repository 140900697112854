// src/routes/AppRoutes.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Landing from '../pages/Landing/Landing';
import Dashboard from '../components/Dashboard';
import Login from '../pages/Login/Login';
import Register from '../pages/Register/Register';
import ContratarWidgetInput from '../pages/Dashboard/ContratarWidgetInput';
import ContratarWidgetPricing from '../pages/Dashboard/ContratarWidgetPricing';
import PaymentSuccess from '../pages/Dashboard/PaymentSuccess';
import ModificarPlan from '../pages/Dashboard/ModificarPlan';
import { useAuth } from '../contexts/AuthContext';

const AppRoutes = () => {
  const { user, loading } = useAuth(); // Asegúrate de manejar el estado de carga

  if (loading) {
    return <div>Cargando...</div>; // Puedes reemplazarlo con un spinner
  }

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={!user ? <Login /> : <Navigate to="/dashboard" />} />
      <Route path="/register" element={!user ? <Register /> : <Navigate to="/dashboard" />} />
      <Route
        path="/dashboard/*" // Ya configurado con '*' para rutas anidadas
        element={user ? <Dashboard /> : <Navigate to="/login" />}
      />
      {/* Rutas para Contratar Widget */}
      <Route
        path="/dashboard/contratar-widget"
        element={user ? <ContratarWidgetInput /> : <Navigate to="/login" />}
      />
      <Route
        path="/dashboard/contratar-widget/pricing"
        element={user ? <ContratarWidgetPricing /> : <Navigate to="/login" />}
      />
      {/* Ruta para PaymentSuccess */}
      <Route
        path="/dashboard/contratar-widget/success"
        element={user ? <PaymentSuccess /> : <Navigate to="/login" />}
      />
      <Route
        path="/dashboard/modificar-plan"
        element={user ? <ModificarPlan /> : <Navigate to="/login" />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
