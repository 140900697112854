// src/pages/Perfil.jsx
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
  Grid,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

const Perfil = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    billing_cycle: '',
    billing_address: {
      city: '',
      region: '',
      address: '',
      postal_code: '',
      country_code: '',
    },
    fiscal_identification: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await api.get(`/customer-details?user_id=${user.id}`);
        setFormData(response.data);
      } catch (err) {
        console.error('Error al obtener detalles del cliente:', err);
        setError('No se pudieron obtener los detalles del cliente.');
      } finally {
        setLoading(false);
      }
    };

    if (user && user.id) {
      fetchCustomerDetails();
    }
  }, [user]);

  const handleLogout = async () => {
    await signOut();
    navigate('/');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('billing_address.')) {
      const field = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        billing_address: {
          ...prev.billing_address,
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await api.put('/update-customer-details', {
        user_id: user.id,
        billing_cycle: formData.billing_cycle,
        billing_address: formData.billing_address,
        fiscal_identification: formData.fiscal_identification,
      });

      if (response.data.success) {
        setSuccess('Datos de facturación actualizados correctamente.');
      }
    } catch (err) {
      console.error('Error al actualizar detalles del cliente:', err);
      setError(err.response?.data?.error || 'No se pudieron actualizar los datos.');
    }
  };

  if (loading) {
    return (
      <Box sx={{ mt: 10, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Cargando datos...</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Perfil
      </Typography>
      <Typography variant="body1">
        <strong>Email:</strong> {user.email}
      </Typography>

      {/* Formulario de facturación */}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Datos de Facturación
        </Typography>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <Grid container spacing={2}>
          {/* Billing Cycle */}
          <Grid item xs={12}>
            <TextField
              select
              label="Día de Facturación"
              name="billing_cycle"
              value={formData.billing_cycle}
              onChange={handleChange}
              fullWidth
              required
            >
              <MenuItem value="01">01</MenuItem>
              <MenuItem value="08">08</MenuItem>
              <MenuItem value="15">15</MenuItem>
              <MenuItem value="22">22</MenuItem>
            </TextField>
          </Grid>

          {/* Billing Address */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Dirección de Facturación</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Ciudad"
              name="billing_address.city"
              value={formData.billing_address.city}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Región"
              name="billing_address.region"
              value={formData.billing_address.region}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Dirección"
              name="billing_address.address"
              value={formData.billing_address.address}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Código Postal"
              name="billing_address.postal_code"
              value={formData.billing_address.postal_code}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Código de País"
              name="billing_address.country_code"
              value={formData.billing_address.country_code}
              onChange={handleChange}
              fullWidth
              required
              helperText="Ejemplo: ES"
            />
          </Grid>

          {/* Fiscal Identification */}
          <Grid item xs={12}>
            <TextField
              label="Identificación Fiscal"
              name="fiscal_identification"
              value={formData.fiscal_identification}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          {/* Botón de Guardar */}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Guardar Cambios
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Botón de cerrar sesión */}
      <Box sx={{ mt: 4 }}>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Cerrar Sesión
        </Button>
      </Box>
    </Box>
  );
};

export default Perfil;
