// src/pages/Login/Login.jsx
import React, { useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  Divider,
  Link as MuiLink
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google'; // Icono de Google
import logo from '../../assets/logo-viewser.png'; // Reemplaza con la ruta de tu logo
import loginImage from '../../assets/login-image2.png'; // Reemplaza con la ruta de tu imagen

const Login = () => {
  const { signIn, user } = useAuth(); // Incluye el estado global `user`
  const navigate = useNavigate();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    const { error } = await signIn({ email, password });
    if (error) {
      setError(error.message);
    }
  };

  // Redirige al Dashboard si el usuario está autenticado
  if (user) {
    navigate('/dashboard');
  }

  const handleGoogleSignIn = async () => {
    // Implementa la lógica de inicio de sesión con Google aquí
    // Por ejemplo:
    // await signInWithGoogle();
  };

  return (
    <Grid container sx={{ height: '100vh', overflow:'hidden' }}>
      {/* Columna Izquierda - Formulario */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between', // Distribuye el espacio entre arriba y abajo
          alignItems: 'center',
          paddingTop: { xs: 10, sm: 12 },    // Más padding en la parte superior
          paddingBottom: { xs: 2, sm: 3 },
          paddingLeft: { xs: 3, sm: 6 },
          paddingRight: { xs: 3, sm: 6 },
          height: '100%', // Ocupa toda la altura del contenedor
        }}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1, // Permite que este bloque ocupe el espacio disponible
          }}
        >
          {/* Título */}
          <Typography
            variant="h3"
            sx={{
              color: '#000000',
              textShadow: '#7894CC 1px 0 19.5px;',
              mb: 6, // Reducir margen inferior para ajustar espacio
              fontWeight: 500,
              textAlign: 'center',
              fontSize: { xs: '2rem', sm: '2.7rem' },
              paddingLeft: 8,
              paddingRight: 8
            }}
          >
            <Box component="span" sx={{ color: '#0F52BD' }}>
              Viewser
            </Box>
            , tu widget de{' '}
            <Box component="span" sx={{ color: '#0F52BD' }}>
              accesibilidad
            </Box>{' '}
            web
          </Typography>

          {/* Contenedor del Formulario con Sombra */}
          <Box
            sx={{
              width: '100%',
              maxWidth: '400px',
              backgroundColor: '#FFFFFF',
              padding: { xs: 2, sm: 3 }, // Reducir padding para ajustar espacio
              borderRadius: '8px',
              boxShadow: '#a7bbe6 1px 0 50.5px', // Sombra similar al Hero
            }}
          >
            {/* Botón de Inicio de Sesión con Google */}
            <Button
              variant="outlined"
              fullWidth
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              sx={{
                borderRadius: '5px',
                textTransform: 'none',
                paddingY: 1.2,
                mb: 0,
                borderColor: '#0F52BD',
                color: '#0F52BD',
                '&:hover': {
                  borderColor: '#0F52BD',
                  backgroundColor: '#f0f0f0',
                },
              }}
            >
              Continuar con Google
            </Button>

            {/* Barra Separadora */}
            <Box sx={{ display: 'flex', alignItems: 'center', my: 1, mt: 2 }}>
              <Divider sx={{ flexGrow: 1 }} />
              <Typography variant="body2" sx={{ mx: 2, color: '#888888' }}>
                o
              </Typography>
              <Divider sx={{ flexGrow: 1 }} />
            </Box>

            {/* Formulario de Inicio de Sesión */}
            <Box component="form" onSubmit={handleLogin}>
              {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
              <TextField
                fullWidth
                label="Correo Electrónico"
                margin="dense" // Cambiar a 'dense' para reducir espacio
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                fullWidth
                label="Contraseña"
                type="password"
                margin="dense" // Cambiar a 'dense' para reducir espacio
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />

              {/* Enlace "¿Olvidó su contraseña?" */}
              <Box sx={{ textAlign: 'left', mt: 1 }}>
                <MuiLink component={Link} to="/forgot-password" variant="body2" sx={{ color: '#0F52BD', textDecoration: 'none' }}>
                  ¿Olvidó su contraseña?
                </MuiLink>
              </Box>

              {/* Botón "Iniciar Sesión" */}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  borderRadius: '5px',
                  textTransform: 'none',
                  paddingY: 1.2,
                  mt: 2,
                }}
              >
                Iniciar Sesión
              </Button>

              <Typography variant="caption" sx={{ mt: 2, color: '#555555', textAlign: 'center', display: 'block' }}>
                Mediante el inicio de sesión, acepta la{' '}
                <MuiLink href="#" underline="hover" sx={{ color: '#0F52BD' }}>
                  Política de privacidad
                </MuiLink>{' '}
                de Viewser y los{' '}
                <MuiLink href="#" underline="hover" sx={{ color: '#0F52BD' }}>
                  Términos de servicio
                </MuiLink>.
              </Typography>
            </Box>
          </Box>

        </Box>

        {/* Logo y Enlaces Inferiores */}
        <Box
          sx={{
            /* position: 'absolute', */
            bottom: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Logo de Viewser"
            sx={{ width: '120px', mb: 1 }}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <MuiLink href="#" variant="body2" sx={{ color: '#74767a', textDecorationColor:'#74767a' }}>
              Español
            </MuiLink>
            <MuiLink href="#" variant="body2" sx={{ color: '#74767a', textDecorationColor:'#74767a' }}>
              Ayuda
            </MuiLink>
            <MuiLink href="#" variant="body2" sx={{ color: '#74767a', textDecorationColor:'#74767a' }}>
              Política de privacidad
            </MuiLink>
          </Box>
        </Box>
      </Grid>

      {/* Columna Derecha - Imagen */}
      <Grid
        item
        xs={false}
        md={6}
        sx={{
          display: { xs: 'none', md: 'block' },
          position: 'relative',
          height:'100%'
        }}
      >
        <Box
          component="img"
          src={loginImage} // Reemplaza con la ruta de tu imagen
          alt="Imagen de Login"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 0.8, // Ajusta la opacidad si es necesario
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Login;
