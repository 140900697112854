// src/pages/ModificarPlan.jsx
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
// import stripePromise from '../../services/stripe';

const ModificarPlan = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { service_id } = location.state || {}; // Obtener service_id desde la navegación
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(''); // Nuevo estado para éxito
  const [selectedPrice, setSelectedPrice] = useState('');

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        // Obtener todos los planes disponibles
        const response = await api.get('/billing-plans');
        setPlans(response.data);
      } catch (err) {
        console.error('Error al obtener planes de facturación:', err);
        setError('No se pudieron obtener los planes de facturación.');
      } finally {
        setLoading(false);
      }
    };

    if (user && user.id && service_id) {
      fetchPlans();
    } else {
      setError('Faltan datos necesarios para modificar el plan.');
      setLoading(false);
    }
  }, [user, service_id]);

  const handlePriceChange = (priceId) => {
    setSelectedPrice(priceId);
  };

  const handleSelectPlan = async () => {
    if (!selectedPrice) {
      setError('Selecciona una cuota para proceder.');
      return;
    }

    try {
      const response = await api.put('/modify-subscription', {
        service_id: service_id,      // Enviar 'service_id'
        new_price_id: selectedPrice, // Enviar 'new_price_id'
      });

      if (response.data.success) {
        setSuccess('La suscripción se ha actualizado correctamente.');
        // Opcional: Navegar después de un breve retraso
        setTimeout(() => {
          navigate('/dashboard/servicios');
        }, 3000);
      } else {
        setError('No se pudo actualizar la suscripción.');
      }
    } catch (err) {
      console.error('Error al modificar suscripción:', err);
      setError('Ocurrió un error al intentar modificar la suscripción.');
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 10, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Cargando planes de facturación...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 10 }}>
      <Typography variant="h4" gutterBottom>
        Modificar Plan de Facturación
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Selecciona un nuevo plan para tu suscripción.
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <Box sx={{ mt: 4, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {plans.map((plan) => (
          <Card key={plan.billing_plan_id} sx={{ width: '100%', maxWidth: 400 }}>
            <CardContent>
              <Typography variant="h6">{plan.plan_name}</Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {plan.plan_desc}
              </Typography>

              {plan.prices && plan.prices.length > 0 && (
                <FormControl component="fieldset" sx={{ mt: 2 }}>
                  <RadioGroup
                    aria-label="billing-period"
                    name={`billing-period-${plan.billing_plan_id}`}
                    value={selectedPrice}
                    onChange={(e) => handlePriceChange(e.target.value)}
                  >
                    {plan.prices.map((price) => (
                      <FormControlLabel
                        key={price.id}
                        value={price.id}
                        control={<Radio />}
                        label={`${capitalizeFirstLetter(price.recurring.interval)}: ${price.currency.toUpperCase()} ${formatPrice(price.unit_amount)} / ${price.recurring.interval}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSelectPlan}
          disabled={!selectedPrice}
        >
          Seleccionar Plan
        </Button>
      </Box>
    </Container>
  );
};

// Función auxiliar para capitalizar la primera letra
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Función auxiliar para formatear el precio
const formatPrice = (amount) => {
  return (amount / 100).toFixed(2);
};

export default ModificarPlan;
